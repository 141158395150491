<template>
  <div v-if="shipment">
    <section class="section is-main-section">
      <div class="columns">
        <div class="column">
          <h2 class="title is-2">
            ການຂົນສົ່ງ - {{ code }}
            <b-button
              @click="viewOldCode"
              v-if="
                shipment.original_shipment &&
                !'canceled'.includes(shipment.status)
              "
              type="is-ghost"
              icon-left="eye"
            >
              {{
                shipment.shipment_type === "SRJ"
                  ? "ເຄື່ອງຖືກຕີກັບ"
                  : "ເຄື່ອງຖືກສົ່ງຕໍ່"
              }}
              - ເບີ່ງລະຫັດເກົ່າ
            </b-button>
            <span
              v-if="shipment.shipment_hold === 1"
              class="subtitle tag is-5 is-danger"
            >
              ເຄື່ອງຖືກອາຢັດ: {{ shipment.hold_reason }}
            </span>
          </h2>
        </div>
        <div class="column">
          <div
            class="buttons is-pulled-right"
            v-if="
              !'canceled,return_to_source,forwarded'.includes(shipment.status)
            "
          >
            <b-tooltip position="is-left" label="ຮັບພັດສະດຸລົງສາຂາ" type="is-danger" style="padding-right:1em;">
              <b-button v-if="'arrived_source_branch,on_the_way_destination_branch'.includes(shipment.status)
              && shipment.to_branch_id == user.branch_id
              && shipment.shipment_hold === 0"
                        class="is-danger is-small"
                        @click="loadsingle"
                        icon-left="clipboard-arrow-down-outline">ຮັບເຄື່ອງລົງສາຂາ</b-button>
            </b-tooltip>
            <b-button
              v-if="
                ((shipment.status !== 'delivered' && user.role === 'ADMIN') ||
                  (shipment.status !== 'delivered' &&
                    (shipment.from_branch_id == user.branch_id ||
                      shipment.to_branch_id == user.branch_id))) &&
                shipment.shipment_hold === 0
              "
              class="is-warning is-small"
              @click="gotoedit"
              icon-left="pencil"
            >
              ແກ້ໄຂ
            </b-button>
            <b-button
              v-if="shipment.shipment_hold === 0"
              icon-left="printer"
              class="is-info is-small"
              @click="printA6"
            >
              ພິມ
            </b-button>
            <b-button
              v-if="shipment.shipment_hold === 0"
              icon-left="printer"
              class="is-small"
              @click="gotoprint()"
            ></b-button>

            <b-button
              v-if="('info_created,arrived_source_branch'.includes(shipment.status) 
              && shipment.from_branch_id == user.branch_id 
              && shipment.shipment_hold === 0) 
              || (!'holded,return_to_source,forwarded,rejected,canceled'.includes(shipment.status)
              && this.user.role == 'ADMIN' )"
              class="is-danger is-small"
              @click="gocancel"
              icon-left="trash-can"
            >
              ຍົກເລີກ
            </b-button>
            <b-button
              v-if="
                shipment.status == 'arrived_destination_branch' &&
                shipment.shipment_hold === 0
              "
              class="is-success is-small"
              @click="gotodeliver"
              left-icon="hand"
            >
              ສົ່ງລູກຄ້າ
            </b-button>
            <router-link
              class="button is-dark is-small"
              v-if="shipment.shipment_script"
              :to="'/ShipmentScriptView/' + shipment.shipment_script.code"
            >
              ໃບນຳສົ່ງ
            </router-link>
            <b-button
              v-if="
                !'shipment_info_created,arrived_source_branch,delivered'.includes(
                  shipment.status
                ) && shipment.shipment_hold === 0
              "
              class="is-small is-warning"
              @click="holdShipment()"
              left-icon="stop"
            >
              ອາຍັດ
            </b-button>
            <section class="b-tooltips" v-if="shipment.shipment_hold === 1">
              <b-tooltip
                label="ສົ່ງກັບເຄື່ອງຖືກອາຢັດ"
                position="is-left"
                type="is-warning"
              >
                <b-button
                  label="ຕີກັບ"
                  class="is-warning button"
                  @click="rejectShipment(shipment.code)"
                  icon-left="undo-variant"
                />
              </b-tooltip>

              <b-tooltip
                label="ສົ່ງຕໍ່ເຄື່ອງຖືກອາຢັດ"
                position="is-left"
                type="is-danger"
              >
                <b-button
                  label=" ສົ່ງຕໍ່"
                  class="is-danger button"
                  @click="forwardShipment(shipment.code)"
                  icon-left="share"
                />
              </b-tooltip>
            </section>
          </div>
        </div>
      </div>

      <hr />
      <div class="columns">
        <div class="column">
          <div class="panel is-primary">
            <div class="panel-heading">
              ຕົ້ນທາງ
              {{ shipment.from_branch ? shipment.from_branch.code : "" }} -
              {{ shipment.from_branch ? shipment.from_branch.name : "" }}
            </div>
            <div style="padding: 1em">
              <b-field label="ສາຂາ" horizontal>
                {{ shipment.from_branch ? shipment.from_branch.code : "" }} -
                {{ shipment.from_branch ? shipment.from_branch.name : "" }}
              </b-field>
              <b-field label="ເບີໂທຜູ້ສົ່ງ" horizontal>
                <router-link
                  v-if="shipment.customer_id != null"
                  :to="'/customer-info/' + shipment.customer_id"
                >
                  {{ shipment.sendertel }}
                </router-link>
                <span v-else>
                  {{ shipment.sendertel }}
                </span>
              </b-field>
              <b-field label="ຊື່ຜູ້ສົ່ງ" horizontal>
                {{ shipment.sendername }}
              </b-field>
              <b-field label="ວັນທີຝາກ" horizontal>
                {{ shipment.created_date | datetime }}
              </b-field>
              <span v-if="!shipment.is_receiver_pay" class="tag is-success">
                ຕົ້ນທາງຈ່າຍຄ່າສົ່ງແລ້ວ
              </span>
            </div>
          </div>

          <div class="title is-2">
            {{ shipmentStatuses[shipment.status].label }}

            <b-button
              @click="viewNewCode"
              v-if="'forwarded,return_to_source'.includes(shipment.status)"
              type="is-ghost"
              icon-left="eye"
            >
              ເບີ່ງລະຫັດໃໝ່
              {{
                shipment.remark.split(":")[
                  shipment.remark.split(":").length - 1
                ]
              }}
            </b-button>
          </div>
        </div>

        <div class="column">
          <div class="panel is-success">
            <div class="panel-heading">
              ປາຍທາງ {{ shipment.to_branch.code }} -
              {{ shipment.to_branch.name }}
            </div>
            <div style="padding: 1em">
              <b-field label="ສາຂາ" horizontal>
                {{ shipment.to_branch.code }} -
                {{ shipment.to_branch.name }}
              </b-field>
              <b-field label="ເບີໂທຜູ້ຮັບ" horizontal>
                {{ shipment.receivertel }}
              </b-field>
              <b-field label="ຊື່ຜູ້ຮັບ" horizontal>
                {{ shipment.receivername }}
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <div
                v-if="!shipment.is_receiver_pay"
                class="title is-4 tag is-success"
              >
                ຈ່າຍແລ້ວ
              </div>
            </div>
            <div class="column">
              <div
                v-if="shipment.is_receiver_pay"
                class="title is-4 tag"
                :class="
                  shipment.status == 'delivered' ? 'is-success' : 'is-danger'
                "
              >
                <span v-if="shipment.is_fee_in_cod">ຫັກຄ່າສົ່ງຈາກ COD</span>
                <span v-if="!shipment.is_fee_in_cod">
                  ຄ່າຂົນສົ່ງເກັບນຳປາຍທາງ
                </span>
                <span v-if="shipment.status == 'delivered'"> - ຈ່າຍແລ້ວ </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="columns">
        <div class="column">
          <div v-if="shipment.parcel" class="panel is-warning">
            <div class="panel-heading">ເຄື່ອງຝາກ</div>
            <div class="table__wrapper">
              <table class="table is-fullwidth">
                <thead>
                  <tr>
                    <th>#</th>
                    <th class="has-text-centered">ປະເພດເຄື່ອງຝາກ</th>
                    <th class="has-text-centered">ຄວາມກວ້າງ (cm)</th>
                    <th class="has-text-centered">ຄວາມຍາວ (cm)</th>
                    <th class="has-text-centered">ຄວາມສູງ (cm)</th>
                    <th class="has-text-centered">ນໍ້າໜັກ (ກິໂລ)</th>
                    <th class="has-text-centered">ຈຳນວນ (ອັນ)</th>
                    <th class="has-text-right">ຄ່າຂົນສົ່ງ</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(parcel, index) in shipment.parcel" :key="index">
                    <td>{{ index + 1 }}</td>
                    <td class="has-text-centered">
                      {{ parcel.parceltype.name }} {{ parcel.remark }}
                    </td>
                    <td class="has-text-right">
                      {{ parcel.width }}
                    </td>
                    <td class="has-text-right">
                      {{ parcel.long }}
                    </td>
                    <td class="has-text-right">
                      {{ parcel.height }}
                    </td>
                    <td class="has-text-right">
                      {{ parcel.weight }}
                    </td>
                    <td class="has-text-right">x {{ parcel.qty }}</td>
                    <td class="has-text-right">
                      <div class="tags">
                        <span class="tag is-info" v-if="parcel.amount > 0">
                          {{ (parcel.amount * 1000) | formatnumber }} ກີບ
                        </span>
                        <span
                          class="tag is-primary"
                          v-if="parcel.amount_thb > 0"
                        >
                          {{ parcel.amount_thb | formatnumber }} ບາດ
                        </span>
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <th colspan="2">ລວມ</th>
                    <th colspan="6" class="has-text-right">
                      <div v-if="shipment.amount > 0">
                        <u style="color: #ff0000; font-size: 1.5em">
                          {{ shipment.amount | formatnumber }} ກີບ
                        </u>
                      </div>
                      <div v-if="shipment.amount_thb > 0">
                        <u style="color: #ff0000; font-size: 1.5em">
                          {{ shipment.amount_thb | formatnumber }} ບາດ
                        </u>
                      </div>
                      <div
                        v-if="
                          !shipment.is_receiver_pay && shipment.fee_payment_mode
                        "
                      >
                        ວິທີຊຳລະ: {{ paymentMethod }}
                      </div>
                    </th>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-3" v-if="shipment.is_cod && shipment.cod && shipment.cod.length > 0">
          <div class="panel is-info">
            <div class="panel-heading">COD</div>
            <table class="table is-fullwidth">
              <thead>
                <tr>
                  <th class="has-text-centered">ສະກຸນເງິນ</th>
                  <th class="has-text-centered">ຈຳນວນເງິນ</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(cod, index) in shipment.cod" :key="index">
                  <td class="has-text-centered">
                    <country-flag
                      :country="cod.ccy.substr(0, 2).toLowerCase()"
                    />
                    {{ cod.ccy }}
                  </td>
                  <td class="has-text-centered">
                    {{ cod.amount | formatnumber }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        
        <div v-if="shipment.status === 'delivered' || shipment.is_receiver_pay===false" 
        :class="isShowPayment?'column is-6':'column is-3'">
          <div class="panel is-danger">
            <div class="panel-heading">
              ຂໍ້ມູນການຊຳລະ
              <b-tooltip
                class="pull-right"
                label="ເບີ່ງຂໍ້ມູນການຊຳລະ"
                type="is-info"
                position="is-left"
              >
                <b-button
                  class="is-small is-danger is-light"
                  :icon-left="isShowPayment ? 'eye-off' : 'eye'"
                  @click="viewPayment()"
                >
                </b-button
              ></b-tooltip>
            </div>
            <div v-if="isShowPayment && paymentDetail!==null" class="has-text-left">
              <b-table
                :data="paymentDetail"
                :striped="true"
                narrowed
                mobile-cards class="is-narrow is-size-7">
                <b-table-column  label="#" v-slot="props">
                  {{ props.index + 1 }}
                </b-table-column>
                <b-table-column field="payment_date" label="ວັນທີຊຳລະ" numeric v-slot="props">
                  {{ props.row.payment_date | datetime }}
                </b-table-column>
                <b-table-column field="amount" label="ຈຳນວນເງີນ"  numeric v-slot="props">
                  {{ props.row.amount | formatnumber }} {{ ` ${props.row.ccy==='LAK'?'ກີບ':(props.row.ccy==='THB'?'ບາດ':'ໂດ່ລ່າ')}`}}
                </b-table-column>
                <b-table-column field="payment_of" label="ຄ່າ"  numeric v-slot="props">
                  {{ props.row.payment_of==='cod'?'COD':(props.row.payment_of==='fee'?'ຄ່າສົ່ງ':'')}}
                </b-table-column>
                 <b-table-column field="payment_of" label="ປະເພດ" numeric v-slot="props">
                  {{ `onepay,selftransfer`.includes(props.row.payment_method)?'ໂອນ':'' }}
                  {{ (`discount`===props.row.payment_method)?'ສ່ວນຫຼຸດ':'' }}
                  {{ (`cash`===props.row.payment_method)?'ເງີນສົດ':'' }}
                  {{ (`debt`===props.row.payment_method)?'ຕິດໜີ້':'' }}
                </b-table-column>
                <b-table-column field="payment_origin" label="ຊຳລະຢູ່" numeric v-slot="props">
                  {{ (props.row.payment_origin==='DES')?'ປາຍທາງ':'ຕົ້ນທາງ' }}
                </b-table-column>
                </b-table>
            </div>
          </div>
        </div>
      </div>

      <div class="columns">
        <div :class="isTrackingShow ? 'column' : 'column is-3'">
          <div class="panel is-grey">
            <div class="panel-heading">
              ຕິດຕາມເຄື່ອງ (Tracking)
              <b-tooltip
                class="pull-right"
                label="ເບີ່ງຂໍ້ມູນ Tracking"
                type="is-info"
                position="is-left"
              >
                <b-button
                  class="is-small is-light"
                  :icon-left="isTrackingShow ? 'eye-off' : 'eye'"
                  @click="viewTracking()"
                >
                </b-button
              ></b-tooltip>
            </div>
            <div class="has-text-left">
              <TrackingComponent :items="trackings" v-if="isTrackingShow" />
            </div>
          </div>
        </div>
        <div :class="isShipmentImageShow?'column is-5':'column is-3'">
          <div class="panel is-warning">
            <div class="panel-heading">
              ຮູບພາບເຄື່ອງ
              <b-tooltip
                class="pull-right"
                label="ເບີ່ງຮູບພາບເຄື່ອງ"
                type="is-info"
                position="is-left"
              >
                <b-button
                  class="is-small is-warning is-light"
                  :icon-left="isShipmentImageShow ? 'eye-off' : 'eye'"
                  @click="viewShipmentImage()"
                >
                </b-button
              ></b-tooltip>
            </div>
            <div
              class="has-text-centered"
              v-if="isShipmentImageShow && shipmentImages.length > 0"
            >
              <b-carousel
                :autoplay="true"
                indicator-custom
                :indicator-inside="false"
                :overlay="gallery"
                @click="switchGallery(true)"
              >
                <b-carousel-item
                  v-for="(item, i) in shipmentImages"
                  :key="i"
                  :indicator-inside="false"
                >
                  <a class="image">
                    <b-image
                      :src="item"
                      :ratio="gallery ? '1by1' : '4by3'"
                    ></b-image>
                  </a>
                </b-carousel-item>
                <span
                  v-if="gallery"
                  @click="switchGallery(false)"
                  class="modal-close is-large"
                />
                <template #indicators="props" v-if="shipmentImages.length > 1">
                  <figure class="al image" :draggable="false">
                    <b-image
                      :draggable="false"
                      :src="getImageName(props.i)"
                      :title="props.i"
                      ratio="4by3"
                    ></b-image>
                  </figure>
                </template>
              </b-carousel>
            </div>

            <div
              class="has-text-centered is-clipped"
              v-if="isShipmentImageShow && shipmentImages.length == 0"
            >
              ບໍ່ມີຮູບພາບ
            </div>
          </div>
        </div>
      </div>

      <div id="slip54" ref="slip54" style="display: none">
        <shipment-slip54 :shipment="shipment" />
      </div>

      <div id="slipKkV1" ref="slipKkV1" style="display: none">
        <shipment-print-kk-v1 :shipment="shipment" />
      </div>

      <b-modal :active.sync="isModalPdfShowing">
        <div
          class="modal-card"
          style="width: auto; min-height: 600px; height: 100%"
        >
          <section class="modal-card-body" style="height: 100%">
            <iframe :src="pdfDataUrl" style="height: 100%; width: 100%" />
          </section>
        </div>
      </b-modal>
    </section>

    <b-loading
      :is-full-page="true"
      v-model="isLoading"
      :can-cancel="false"
    ></b-loading>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import { get } from "vuex-pathify";
import helper from "../helper";
import TrackingComponent from "../components/TrackingComponent.vue";
import ShipmentSlip54 from "./ShipmentSlip54";
import html2canvas from "html2canvas";
import ShipmentPrintKkV1 from "./ShipmentPrintKkV1";
import pdfMake from "pdfmake/build/pdfmake";
//import { ToastProgrammatic as Toast } from 'buefy'

export default {
  name: "ShipmentView",
  components: {
    ShipmentSlip54,
    TrackingComponent,
    ShipmentPrintKkV1,
  },
  computed: {
    user: get("user"),
    shipmentStatuses: get("shipmentStatuses"),
    paymentmethods: get("paymentmethods"),
    exchangerate: get("exchangerate"),
    paymentMethod() {
      if (this.shipment) {
        let t = this.paymentmethods.filter(
          (i) => i.id === this.shipment.fee_payment_mode.toLowerCase()
        );
        if (t.length > 0) {
          return t[0].name;
        }
      }

      return "ບໍ່ໄດ້ເລືອກ";
    },
  },
  props: {
    propShipment: {
      type: Object,
    },
  },
  data() {
    return {
      ccy: "LAK",
      ccys: [{ code: "LAK" }, { code: "THB" }],
      code: null,
      shipment: null,
      isShowModalDelivery: false,
      amount: null,
      trackings: null,
      isModalPdfShowing: false,
      pdfDataUrl: null,
      activeTab: 0,
      isLoading: false,
      shipid: 0,
      shipmentImages: [],
      gallery: false,
      isTrackingShow: false,
      isShipmentImageShow: false,
      isShowPayment:false,
      paymentDetail:null,
    };
  },
  watch: {
    shipment: function () {
      this.getShipmentImage();
    },
  },
  mounted() {
    this.code = this.$route.params["code"];

    //form prop
    if (this.propShipment) {
      this.shipment = this.propShipment;
      this.code = this.propShipment.code;
      if (this.shipment.status === helper.SHIPMENT_STATUS.INFO_CREATED) {
        this.gotoedit();
      } else {
        this.shipment.from_branch =
          this.propShipment.branch_branchToshipment_from_branch_id;
        this.shipment.to_branch =
          this.propShipment.branch_branchToshipment_to_branch_id;
        this.getTrackings();
      }
    }

    //from route
    if (this.code) {
      this.get();
    }
  },
  methods: {
    loadsingle(){
      this.$buefy.dialog.confirm({
        title: "ຢືນຢັນຮັບເຄື່ອງລົງສາຂາ",
        message: "ທ່ານຕ້ອງການ <b>ຮັບເຄື່ອງລົງສາຂາ</b> ແທ້ບໍ?<br><p class=\"0.75rem is-italic\" >(ຂັ້ນຕອນປົກກະຕິຂອງການຮັບເຄື່ອງ ຄວນປະຕິບັດຢູ່ ກວດເຄື່ອງລົງລົດ)</p>",
        confirmText: "ແມ່ນ, ຮັບເຄື່ອງລົງສາຂາ",
        cancelText: "ບໍ່",
        type: "is-warning",
        hasIcon: true,
        onConfirm: () => {
          axios
              .post(`parcels/loadsingle`,{code: this.shipment.code})
              .then((res) => {
                console.log(res);
                Swal.fire("ສຳເລັດ", "", "success");
                this.shipment.status = "arrived_destination_branch";
                this.getTrackings();
              })
              .catch((error) => {
                this.$buefy.toast.open({
                  duration: 7000,
                  message: `ຮັບເຄື່ອງລົງສາຂາ ບໍ່ສຳເລັດ, <b>${error}</b>`,
                  position: "is-top",
                  type: "is-danger",
                });
              });
        },
      });
    },
    viewPayment(){
      this.isShowPayment=!this.isShowPayment;
      if(this.isShowPayment && this.paymentDetail ===null){
         axios.get("shipments/payment/" + this.shipment.id).then((r) => {
          this.paymentDetail = r;
        });
      }
    },
    getShipmentImage() {
      if (this.isShipmentImageShow) {
        axios.get("shipments/images/" + this.shipment.id).then((r) => {
          this.shipmentImages = r;
        });
      }
    },
    viewShipmentImage() {
      this.isShipmentImageShow = !this.isShipmentImageShow;
      if (this.isShipmentImageShow && this.shipmentImages.length === 0)
        this.getShipmentImage();
    },
    viewTracking() {
      this.isTrackingShow = !this.isTrackingShow;
      if (this.isTrackingShow && this.trackings === null) this.getTrackings();
    },
    viewOldCode() {
      const newCode = this.shipment.original_shipment;
      this.shipid = newCode;
      this.get();
    },
    viewNewCode() {
      const newCode =
        this.shipment.remark.split(":")[
          this.shipment.remark.split(":").length - 1
        ];
      this.code = newCode;
      this.shipid = 0;
      this.get();
    },
    getImageName(index) {
      return this.shipmentImages[index];
    },
    switchGallery(value) {
      this.gallery = value;
      if (value) {
        return document.documentElement.classList.add("is-clipped");
      } else {
        return document.documentElement.classList.remove("is-clipped");
      }
    },
    addPayment() {
      this.shipment.payments.push({
        ccy: this.ccy,
        type: null,
        amount: null,
      });
      this.$forceUpdate();
    },
    removePayment(index) {
      if (this.shipment.payments[index]) {
        this.shipment.payments.splice(index, 1);
        this.$forceUpdate();
      }
    },
    filterCcy(e) {
      console.log(e);
    },
    getChanges() {
      const c = this.sumLak - this.getTotalShipment("LAK");
      return c;
    },
    getCod(ccy) {
      const cod = this._.find(this.shipment.cod, (c) => c.ccy == ccy);
      return cod ? cod.amount : 0;
    },
    getTrackings() {
      if (!this.isTrackingShow) return;
      axios.get("track/" + this.shipment.code).then((r) => {
        if (r && r.trackings) {
          this._.map(r.trackings, (i) => {
            i.tag = i.date;
            i.htmlMode = true;
            i.type = "circle";
            i.color = "green";
            i.content = `
                <div>
                  <div style='font-size: 1.5em'>${i.title}</div>
                  <div style='font-size: 1em;color: #777777'>${i.description}</div>
                </div>
                `;
            this.trackings = r.trackings;
          });
        }
      });
    },
    getTotalShipment(ccy) {
      var sum = { LAK: 0, THB: 0 };
      if (this.shipment) {
        if (this.shipment.is_receiver_pay && !this.shipment.is_fee_in_cod) {
          sum.LAK += this.shipment.amount;
          sum.THB += this.shipment.amount_thb;
        }
        if (this.shipment.is_cod && this.shipment.cod) {
          for (let index = 0; index < this.shipment.cod.length; index++) {
            const element = this.shipment.cod[index];
            sum[element.ccy] += element.amount;
          }
        }
      }
      return sum[ccy];
    },
    get() {
      axios
        .get("shipments/get/" + this.code, {
          params: { shipmentId: this.shipid },
        })
        .then((r) => {
          //console.log(r);
          this.shipment = r;
          this.code = this.shipment.code;
          if (this.shipment.status === helper.SHIPMENT_STATUS.INFO_CREATED) {
            this.gotoedit();
          } else {
            this.shipment.from_branch =
              r.branch_branchToshipment_from_branch_id;
            this.shipment.to_branch = r.branch_branchToshipment_to_branch_id;
            this.getTrackings();
          }
          this.$forceUpdate();
        })
        .catch((e) => {
          console.log(e);
          Swal.fire("ບໍ່ພົບ", e, "info");
        });
    },
    gotoedit() {
      this.$router.push({ path: `/updateshipment/${this.code}` });
    },
    gocancel() {
      this.$buefy.dialog.confirm({
        title: "ຢືນຢັນການຍົກເລີກໃບບິນ",
        message: "ທ່ານຕ້ອງການ <b>ຍົກເລີກໃບບິນ</b> ແທ້ບໍ?",
        confirmText: "ແມ່ນ, ຍົກເລີກໃບບິນ",
        cancelText: "ບໍ່",
        type: "is-warning",
        hasIcon: true,
        onConfirm: () => {
          axios
            .delete(`shipments/cancel/${this.shipment.id}`)
            .then((res) => {
              console.log(res);
              Swal.fire("ສຳເລັດ", "", "success");
              this.shipment.status = "canceled";
              this.getTrackings();
              //this.$router.replace(`/shipment/${res.code}`);
              //this.get();
            })
            .catch((error) => {
              this.$buefy.toast.open({
                duration: 7000,
                message: `ການຍົກເລີກບໍ່ສຳເລັດ, <b>${error}</b>`,
                position: "is-top",
                type: "is-danger",
              });
            });
        },
      });
    },
    gotoprint() {
      if (this.isAndroid) {
        try {
          //todo: gen Base64 image
          //  console.log(this.$refs.slip54)
          this.isLoading = true;
          let th = this;
          html2canvas(this.$refs.slip54, {
            width: 384,
            scale: 2,
            onclone: function (clonedDoc) {
              // console.log(clonedDoc)
              clonedDoc.getElementById("slip54").style.display = "block";
            },
          }).then(function (canvas) {
            th.isLoading = false;
            let imgBase64 = canvas.toDataURL("image/png", 0.5);
            window.AndroidApp.printImageBase64(imgBase64, 2); // print 2 copy
          });
        } catch (e) {
          this.isLoading = false;
          console.log(e);
        }
      } else {
        this.$router.push({
          name: "shipmentprint",
          path: `/shipmentprint/${this.code}`,
          params: { proShipment: this.shipment },
        });
      }
    },
    gotodeliver() {
      if (this.shipment.is_receiver_pay || this.shipment.is_cod) {
        this.$router.push({ path: `/todeliverparcel/${this.code}` });
      } else {
        //todo confirm dialog here
        this.$buefy.dialog.confirm({
          title: `ຢືນຢັນການສົ່ງລູກຄ້າ ${this.shipment.code}`,
          message: `ທ່ານຕ້ອງການ ມອບເຄື່ອງໃຫ້ກັບ ${this.shipment.receivername} ${this.shipment.receivertel}  ແທ້ບໍ່`,
          confirmText: "ແມ່ນ, ມອບເຄື່ອງ",
          cancelText: "ບໍ່",
          type: "is-danger",
          hasIcon: true,
          onConfirm: () => {
            axios
              .post(`parcels/delivernew/${this.shipment.id}`, {
                shipment: this.shipment,
              })
              .then((res) => {
                console.log(res);
                Swal.fire("ສຳເລັດ", "", "success");
                this.shipment.status = "delivered";
                this.shipment.delivery_date = new Date();
                this.getTrackings();
                //this.get();
              })
              .catch((error) => {
                this.$buefy.toast.open({
                  duration: 7000,
                  message: `ການມອບເຄື່ອງສຳເລັດ, <b>${error}</b> `,
                  position: "is-top",
                  type: "is-danger",
                });
              });
          },
        });
      }
    },
    tracking() {
      this.$router.push({ path: "/tracking/" + this.code });
    },
    rejectShipment(code) {
      this.$router.push({
        path: `/updateshipment/${code}`,
        query: { command: "REJECT" },
      });
    },
    forwardShipment(code) {
      this.$router.push({
        path: `/updateshipment/${code}`,
        query: { command: "FORWARD" },
      });
    },
    holdShipment() {
      this.$buefy.dialog.prompt({
        title: "ຢືນຢັນການອາຍັດ",
        message: "ທ່ານຕ້ອງການ <b>ອາຍັດລາຍການສົ່ງເຄື່ອງນີ້</b> ແທ້ບໍ?",
        confirmText: "ແມ່ນ, ອາຍັດລາຍການ",
        cancelText: "ບໍ່",
        type: "is-warning",
        inputAttrs: {
          placeholder: "ເຫດຜົນການອາຍັດ",
        },
        trapFocus: true,
        onConfirm: (value) => {
          const holdReason = value;
          axios
            .patch(`shipments/hold/${this.shipment.id}`, { holdReason: value })
            .then((res) => {
              console.log(res);
              Swal.fire("ສຳເລັດ", "", "success");
              this.shipment.shipment_hold = 1;
              this.shipment.hold_reason = holdReason;
              this.getTrackings();
            })
            .catch((error) => {
              this.$buefy.toast.open({
                duration: 7000,
                message: `ການອາຢັດເກີດຂໍ້ຜິດພາດ, <b>${error}</b>`,
                position: "is-top",
                type: "is-danger",
              });
            });
        },
      });
    },
    printA6() {
      //const _this = this;
      if (this.isAndroid) {
        this.gotoprint();
        return;
      }
      try {
        html2canvas(this.$refs.slipKkV1, {
          width: 1240 * 2,
          scale: 1,
          onclone: function (clonedDoc) {
            // console.log(clonedDoc)
            clonedDoc.getElementById("slipKkV1").style.display = "block";
          },
        }).then((canvas) => {
          var img = canvas.toDataURL("image/png", 1);
          //printJS({printable:img, type: 'image', imageStyle: 'width:590px'});
          /* _this.pdfDataUrl = img
           _this.isModalPdfShowing = true*/
          const documentDefinetion = {
            pageMargins: [2, 5, 2, 2],
            pageSize: "A6",
            pageOrientation: "portrait",
            content: [
              {
                image: img,
                width: 295 * 2,
              },
            ],
          };
          const pdf = pdfMake.createPdf(documentDefinetion);
          pdf.getDataUrl((url) => {
            this.pdfDataUrl = url;
            this.isModalPdfShowing = true;
          });
        });
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.table__wrapper {
  overflow-x: auto;
}

.is-active .al img {
  border: 1px solid #fff;
  filter: grayscale(0%);
}
.al img {
  border: 1px solid transparent;
  filter: grayscale(100%);
}

.b-tooltips {
  .b-tooltip:not(:last-child) {
    margin-right: 0.5em;
  }
  .b-tooltip {
    margin-bottom: 0.5em;
  }
}
</style>
