<template>
  <div class="slip54">
    <div style="text-align: center">
      <img src="../assets/logo_bw.png" alt="kiangkai" width="50" height="50" style="vertical-align: middle; padding: 10px;" />
      <u class="title">ບໍລິສັດ ກຽງໄກ ຂົນສົ່ງດ່ວນ</u>
      <div>
        ວັນທີ: {{ shipment.created_date | datetime }}
      </div>
      <div>
        ເລກທີ: {{ shipment.code }}
      </div>
      <table class="table is-fullwidth is-bordered is-narrow">
        <tbody>
        <tr>
          <td rowspan="2" style="width: 80px">ຕົ້ນທາງ</td>
          <td>{{shipment.from_branch.code}}-{{shipment.from_branch.name}}</td>
        </tr>
        <tr>
          <td>ຜູ້ສົ່ງ: {{shipment.sendername}}, {{shipment.sendertel}}</td>
        </tr>
        <tr>
          <td rowspan="2" style="width: 80px">ປາຍທາງ</td>
          <td>{{shipment.to_branch.code}}-{{shipment.to_branch.name}}</td>
        </tr>
        <tr>
          <td>ຜູ້ຮັບ: {{shipment.receivername}}, {{shipment.receivertel}}</td>
        </tr>
        </tbody>
      </table>
      <table class="table is-fullwidth is-bordered is-narrow">
        <thead>
        <tr>
          <th colspan="7" class="has-text-centered  kk-text-small">ລາຍການເຄື່ອງຝາກ</th>
        </tr>
        <tr>
          <th>#</th>
          <th class="has-text-centered  kk-text-small">ປະເພດ</th>
          <th class="has-text-centered  kk-text-small">ນໍ້າໜັກ</th>
          <th class="has-text-centered  kk-text-small">ຈ/ນ</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(parcel, index) in shipment.parcel" :key="'x' + index">
          <td>{{ index + 1 }}</td>
          <td class="has-text-centered kk-text-small">
            {{ parcel.parceltype.name }}
          </td>
          <td class="has-text-right  kk-text-small">{{ parcel.weight }} ກິໂລ</td>
          <td class="has-text-right  kk-text-small"> {{ parcel.qty }}</td>
        </tr>
        <tr>
          <th colspan="4" class="has-text-centered  kk-text-small">COD</th>
        </tr>
        <tr>
          <td colspan="2">ຄ່າຂົນສົ່ງ</td>
          <td colspan="2"><div style=" font-weight: bold; font-size: 1em">
            <u>{{ shipment.amount | formatnumber }}</u>
            <u v-if="shipment.is_receiver_pay"> (ເກັບປາຍທາງ)</u>
            <u v-else> (ຈ່າຍແລ້ວ)</u>
          </div></td>
        </tr>
        <tr v-for="(cod, index) in shipment.cod" :key="'a' +index">
          <td colspan="2" class="has-text-centered kk-text-small">{{ cod.ccy }}</td>
          <td colspan="2" class="has-text-centered kk-text-small">
            {{ cod.amount | formatnumber }}
          </td>
        </tr>
        </tbody>
      </table>

      <div>
        <vue-qrcode :value="'https://kiangkai.com/#/track/' + shipment.code" :scale="5" :margin="1" style="display: inline-block !important; vertical-align: middle;"/>

        <div style=" border: 5px solid; width: 90px; height: 90px; font-size: 3em; font-weight: 900; vertical-align: middle; text-align: center; margin-top: 5px; margin-left: 10px; padding-top:5px; display:inline-block">
          {{ shipment.sq_number }}
        </div>
      </div>

      <vue-barcode :value="shipment.code" :height="50"/>

    </div>

    <hr style=" border-top: 5px dotted ; margin-bottom: 40px;"/>
    <!-- slip parth -->
    <div style="text-align: center">
      <img src="../assets/logo_bw.png" alt="kiangkai" width="50" height="50" style="vertical-align: middle;  padding: 10px;" />
      <u class="title">ບໍລິສັດ ກຽງໄກ ຂົນສົ່ງດ່ວນ</u>
      <div>
        ວັນທີ: {{ shipment.created_date | datetime }}
      </div>
      <div>
        ເລກທີ: {{ shipment.code }}
      </div>
      <div style=" font-size: 3em; font-weight: 900; overflow:hidden; background: black; color: white">
        {{ shipment.to_branch.name }}
      </div>
      <table class="table is-fullwidth is-bordered is-narrow">
        <tbody>
        <tr>
          <td rowspan="2" style="width: 80px">ຕົ້ນທາງ</td>
          <td>{{shipment.from_branch.code}}-{{shipment.from_branch.name}}</td>
        </tr>
        <tr>
          <td>ຜູ້ສົ່ງ: {{shipment.sendername}}, {{shipment.sendertel}}</td>
        </tr>
        <tr> <td rowspan="2" style="width: 80px">ປາຍທາງ</td>
          <td>{{shipment.to_branch.code}}-{{shipment.to_branch.name}}</td>
        </tr>
        <tr>
          <td>ຜູ້ຮັບ: {{shipment.receivername}}, {{shipment.receivertel}}</td>
        </tr>
        </tbody>
      </table>
      <table class="table is-fullwidth is-bordered is-narrow">
        <thead>
        <tr>
          <th colspan="7" class="has-text-centered  kk-text-small">ລາຍການເຄື່ອງຝາກ</th>
        </tr>
        <tr>
          <th>#</th>
          <th class="has-text-centered  kk-text-small">ປະເພດ</th>
          <th class="has-text-centered  kk-text-small">ນໍ້າໜັກ</th>
          <th class="has-text-centered  kk-text-small">ຈ/ນ</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(parcel, index) in shipment.parcel" :key="index">
          <td>{{ index + 1 }}</td>
          <td class="has-text-centered kk-text-small">
            {{ parcel.parceltype.name }}
          </td>
          <td class="has-text-right  kk-text-small">{{ parcel.weight }} ກິໂລ</td>
          <td class="has-text-right  kk-text-small"> {{ parcel.qty }}</td>
        </tr>
        <tr>
          <th colspan="4" class="has-text-centered  kk-text-small">COD</th>
        </tr>
        <tr>
          <td colspan="2">ຄ່າຂົນສົ່ງ</td>
          <td colspan="2"><div style=" font-weight: bold; font-size: 1em">
            <u>{{ shipment.amount | formatnumber }}</u>
            <u v-if="shipment.is_receiver_pay"> (ເກັບປາຍທາງ)</u>
            <u v-else> (ຈ່າຍແລ້ວ)</u>
          </div></td>
        </tr>
        <tr v-for="(cod, index) in shipment.cod" :key="'y' +index">
          <td colspan="2" class="has-text-centered kk-text-small">{{ cod.ccy }}</td>
          <td colspan="2" class="has-text-centered kk-text-small">
            {{ cod.amount | formatnumber }}
          </td>
        </tr>
        </tbody>
      </table>

      <table  class="table is-fullwidth is-bordered is-narrow ">
        <thead>
        <tr>
          <th colspan="2" class="has-text-centered  kk-text ">ລວມ</th>
        </tr>
        </thead>
        <tbody>
        <tr >
          <td class="has-text-centered kk-text">ກີບ</td>
          <td class="has-text-centered kk-text-large">{{ this.totalLak | formatnumber}}</td>
        </tr>
        <tr v-if="this.codThb > 0">
          <td class="has-text-centered kk-text">ບາດ</td>
          <td class="has-text-centered kk-text-large">{{ this.codThb | formatnumber}}</td>
        </tr>
        <tr v-if="this.codUsd > 0">
          <td class="has-text-centered kk-text">ໂດລາ</td>
          <td class="has-text-centered kk-text-large">{{ this.codUsd | formatnumber}}</td>
        </tr>
        </tbody>
      </table>



        <div>
          <vue-qrcode :value="'https://kiangkai.com/#/track/' + shipment.code" :scale="5" :margin="1" style="display: inline-block !important; vertical-align: middle;"/>

        <div style=" border: 5px solid; width: 90px; height: 90px; font-size: 3em; font-weight: 900; vertical-align: middle; text-align: center; margin-top: 5px; margin-left: 10px; padding-top:5px; display:inline-block">
          {{ shipment.sq_number }}
        </div>
          </div>



      <vue-barcode :value="shipment.code" :height="50"/>

    </div>

  </div>
</template>

<script>
import VueQrcode from 'vue-qrcode'
import VueBarcode from 'vue-barcode'
import  _ from 'lodash'
export default {
  name: 'ShipmentSlip54',
  components: {VueQrcode, VueBarcode},
  props: {
    shipment: {
      type: Object,
      required: true
    }
  },
  computed: {
    totalLak () {
      return (
          (this.shipment.is_receiver_pay && !this.shipment.is_fee_in_cod
              ? this.shipment.amount
              : 0) + this.codLak
      );
    },
    codLak () {
      if(this.shipment.is_cod) {
        return _.sumBy(this.shipment.cod,(itm) => itm.ccy === 'LAK' ? itm.amount: 0)
      }
      return 0
    },
    codThb () {
      if(this.shipment.is_cod) {
        return _.sumBy(this.shipment.cod,(itm) => itm.ccy === 'THB' ? itm.amount: 0)
      }
      return 0
    },
    codUsd () {
      if(this.shipment.is_cod) {
        return _.sumBy(this.shipment.cod,(itm) => itm.ccy === 'USD' ? itm.amount: 0)
      }
      return 0
    }
  }
}
</script>

<style scoped>
.slip54 {
  font-weight: bold;
  font-size: 18px !important;
  padding: 8px;
  width: 384px;
  min-height: 100px;
  color: #000000 !important;
}

.table.is-bordered td, .table.is-bordered th {
  border-width: 2px;
  border-color: #000000;
}

.title {
  font-size: 1.5em;
  font-weight: 900;
  overflow: hidden
}

.kk-text {
  font-size: 1.3em !important;
  font-weight: bold !important;
}

.kk-text-small {
  font-size: 1em !important;
  font-weight: bold !important;
}

.kk-text-large {
  font-size: 3em !important;
  font-weight:bolder;
}
td {
  vertical-align: middle;
}

</style>